.condition-check {
    font-size: 0.9rem;
    color: gray;
    list-style-type: none;
    padding: 0;
}

.check-icon {
    margin-right: 0.4rem;
    opacity: 0.7;
}