#wizard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#form-container {
    background-color: #e4e4e4;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    color: #252829;
}
#form-container img {
    margin: 0 auto 4% auto;
}
#form-content {
    padding-bottom: 1rem;
    margin: auto;
    padding-inline: 30px;
    width: 100%;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 50%);
}
