/* Popup */
#spanish-popup {
    color: #7f8c8d;
}
#spanish-popup .popup-cancel {
    padding: 12px;
    float: none;
    min-width: 220px;
    transition: all 0.2s ease-in;
    color: #fff;
    border-radius: 30px;
    box-shadow: inset 0 -3px 2px rgb(0 0 0 / 20%);
    font-size: 0.825em;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    margin-bottom: 1em;
    border: none;
    cursor: pointer;
    background-color: #41566a;
}
#spanish-popup .popup-close {
    padding-block: 0;
    transition: all 0.2s ease-in;
    color: #fff;
    border-radius: 30px;
    box-shadow: inset 0 -3px 2px rgb(0 0 0 / 20%);
    font-size: 2em;
    text-decoration: none;
    border: none;
    cursor: pointer;
    background-color: #41566a;
}
/* Header */
header {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 0.75em;
    font-weight: 800;
}
header ul {
    display: flex;
    list-style-type: none;
}
header div.nav-routes {
    margin: 10px;
    margin-left: 5%;
}

header div.language-picker {
    margin: 10px;
    margin-right: 5%;
}
header div.language-picker a {
    text-decoration: none;
}
header a {
    color: white;
}
header a:hover {
    color: white;
    text-decoration: none;
}
#navbar {
    font-family: 'Raleway', sans-serif;
    margin-right: 24px;
    width: 60%;
    height: 40px;
    background-color: #41566a;
    max-width: 100vw;
    border-bottom-color: white;
    border-bottom-width: thin;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
/* Footer */
footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
}
footer hr {
    width: 60%;
    opacity: 1;
}
#disclaimer-container {
    font-size: 15px;
    display: flex;
    justify-content: center;
}
#disclaimer-container p {
    width: 60%;
}
#disclaimer-link {
    color: white;
    font-weight: 600;
    text-decoration: underline;
}
#metadata-container {
    width: 60%;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}
#content-wrap {
    padding-bottom: 2.5rem;
}
