.section-header {
    font-weight: 500;
    font-size: 24px;
    color: #252829;
    margin-bottom: 0;
    margin-top: 20px;
}
.sub-header.initial-header {
    color: #4a4a4a;
    font-weight: 600;
    font-size: 18px;
}

#login-container {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    margin: 60px auto;
    background-color: white;
    color: #252829;
    padding: 20px 50px;
}
#action-container a {
    color: #0b3a5d;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-decoration: underline;
}
#action-container {
    margin-block: 20px;
}
#notice-container a {
    color: #0b3a5d;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-decoration: underline;
}
