.section-header {
    font-weight: 500;
    font-size: 24px;
    color: #252829;
    margin-bottom: 0;
    margin-top: 20px;
}
.sub-header.initial-header {
    color: #4a4a4a;
    font-weight: 600;
    font-size: 18px;
}
.attestation-label {
    color: #4a4a4a;
    font-weight: 500;
}
.next-button,
.next-button:focus,
.next-button:active,
.next-button:hover,
.next-button:disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.6rem;
    border-color: #0b3a5d;
    background-color: #0b3a5d;
    font-size: 20px;
}

.back-button,
.back-button:focus,
.back-button:active,
.back-button:hover,
.back-button:disabled {
    border-color: #0b3a5d;
    color: #0b3a5d;
    background-color: white;
    font-size: 20px;
}
.attestation-label a {
    color: #0b3a5d;
}
#member-verification hr {
    margin: 1.5rem 0;
}
#password-requirements {
    font-size: 13px;
    opacity: 0.8;
}
.tooltip-member-verification {
    padding-top: 0.5rem;
    padding-left: 0.3rem;
}
.tooltip-memberId-message {
    padding-left: 1rem;
    padding-right: 1.5rem;
    font-size: 14px;
}
.tooltip-last-name-message {
    padding: 1rem;
    font-size: 14px;
}
.danger-text {
    color: red;
}
