.form-control:focus,
.form-check-input:focus {
    box-shadow: 0 0 0 0.15rem rgb(13 110 253 / 25%);
}

#text-input {
    &-password, &-confirmedPassword {
        background-image: none;
    }
}

.input-wrapper {
    position: relative;
    width: 100%;

    .show-button {
        border: 1px solid transparent;
        background: none;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.375rem 0.75rem;
        color: gray;
    }
}
