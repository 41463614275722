body {
    background-image: url('../img/Backgroung_Large.png');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: cover;
    background-color: #18324a;
    color: white;
    font-family: 'Raleway', sans-serif;
    position: relative;
    min-height: 100vh;
}
.text-navy {
    color: #0b3a5d;
}
